import React, { useState, useRef, useContext } from "react";
import styled, { ThemeProvider, css } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import useScroll from "hooks/userScroll";
import { useTranslation } from "react-i18next";
import { LanguageSelector, Private } from "components/utils";
import { Hamburger } from "style/global.styled";
import useClickAway from "hooks/useClickAway";
import { UserContext } from "contexts/UserContext";
import { CartIcon } from "components/cart";
import { ROLES } from "utils/contants";
import images from "assets/images";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.light};
  text-transform: uppercase;
  font-size: 20px;
  @media (max-width: 1400px) {
    font-size: 16px;
  }
`;

const Nav = styled.nav`
  position: relative;
  width: 100%;
  min-width: 1400px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: height 0.5s;
  height: 80px;
  @media (max-width: 1400px) {
    min-width: 0;
  }
  @media (max-width: 1200px) {
    height: 70px;
  }
`;

const Logo = styled.img`
  display: none;
  width: 160px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 1200px) {
    display: initial;
  }
`;

const NavItems = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    width: 250px;
    min-height: 100vh;
    top: 0;
    right: ${props => (props.theme.open ? 0 : "-260px")};
    padding: 100px 0;
    background-image: url(${images.BackgroundRed});
    border-left: 1px solid #fff;
    transition: right 0.5s;
    box-shadow: 0 0 10px ${props => props.theme.colors.primary};
  }
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  :last-of-type {
    margin: 0 20px;
  }
  & > * {
    margin: 0 20px;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    & > * {
      margin: 15px 0 !important;
    }
  }
`;

const Item = styled(Link)`
  position: relative;
  transition: all 0.3s;
  letter-spacing: 2px;
  :hover {
    opacity: 0.7;
  }
  ${p =>
    p.selected &&
    css`
      :before,
      :after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: ${p.theme.colors.light};
      }
      :before {
        top: -12px;
      }
      :after {
        bottom: -12px;
      }
    `}
`;

const MenuButton = styled.div`
  padding: 11px 0;
  cursor: pointer;
  z-index: 100;
  margin-right: 20px;
  margin-left: auto;
  @media (min-width: 1200px) {
    display: none;
  }
`;

const User = styled.div`
  position: absolute;
  top: 60px;
  font-size: 18px;
  text-align: center;
  display: flex;
  text-transform: capitalize;
  div:nth-child(1) {
    margin-right: 5px;
    color: #ffffff90;
  }
  @media (min-width: 1200px) {
    top: -170px;
    right: 20px;
    font-size: 22px;
    font-family: EncSansMed;
    flex-direction: column;
    text-shadow: 2px 2px 10px ${props => props.theme.colors.primary},
      -2px -2px 10px ${props => props.theme.colors.primary};
  }
`;

const Navbar = ({ isHomePage }) => {
  const { t: translate } = useTranslation();
  const {
    state: { isLoggedIn, user }
  } = useContext(UserContext);
  const { pathname } = useLocation();

  const [scrolledDown, setScrolledDown] = useState(false);
  const [open, setOpen] = useState(false);
  const links = ["/", "/foods", "/contact", "/map"];

  const menuRef = useRef(null);

  const closeMenu = () => open && setOpen(false);

  useClickAway(menuRef, closeMenu);

  useScroll(() => {
    const start = window.innerWidth <= 1200 ? 0 : 222;
    if (open) closeMenu();
    if (window.scrollY > start && !scrolledDown) setScrolledDown(true);
    else if (window.scrollY <= start && scrolledDown) setScrolledDown(false);
  });

  const isSelected = link => {
    const splittedPath = pathname.split("/").slice(0, 2);
    return splittedPath.join("/") === link;
  };

  return (
    <ThemeProvider theme={{ open, scrolledDown }}>
      <Wrapper>
        <Nav>
         {!isHomePage && <Logo src={images.LogoHeader} alt="Logo" />}
          <NavItems ref={menuRef}>
            <Menu>
              {translate("navtexts", { returnObjects: true }).map(
                (text, index) => (
                  <Item
                    to={links[index]}
                    key={index}
                    onClick={closeMenu}
                    selected={isSelected(links[index])}
                  >
                    {text}
                  </Item>
                )
              )}
              {isLoggedIn ? (
                <Item
                  to="/profile"
                  onClick={closeMenu}
                  selected={isSelected("/profile")}
                >
                  {translate("profile")}
                </Item>
              ) : (
                <Item
                  to="/login"
                  onClick={closeMenu}
                  selected={isSelected("/login")}
                >
                  {translate("login")}
                </Item>
              )}
              <Private
                roles={[ROLES.ADMIN, ROLES.MANAGER, ROLES.SHOP_ASSISTANT]}
              >
                <Item
                  to="/dashboard"
                  onClick={closeMenu}
                  selected={isSelected("/dashboard")}
                >
                  {translate("dashboard.title")}
                </Item>
              </Private>
              <Item to="/cart" onClick={closeMenu}>
                <CartIcon />
              </Item>
              <LanguageSelector />
            </Menu>
            <Private roles={[ROLES.ADMIN, ROLES.MANAGER, ROLES.SHOP_ASSISTANT]}>
              <User>
                <div>{translate("loggedIn")}: </div>
                <div>{user.name}</div>
              </User>
            </Private>
          </NavItems>
          <MenuButton onClick={() => setOpen(!open)}>
            <Hamburger />
          </MenuButton>
        </Nav>
      </Wrapper>
    </ThemeProvider>
  );
};

export default Navbar;
