import React, { useContext, useEffect, useState } from "react";
import { OrderContext } from "contexts/OrderContext";
import { BaseTable } from "components/utils";
import { useTranslation } from "react-i18next";
import DailyRow from "./daily-row";
import { PAYMENT_METHOD, ROLES } from "utils/contants";
import { format, isValid } from "date-fns";
import styled from "styled-components";
import { UserContext } from "contexts/UserContext";
import DatePicker from "components/utils/date-picker";

const dateFormat = "yyyy-MM-dd";

const DatePickerWrapper = styled.div`
  display: ${p => (p.hidden ? "none" : "flex")};
  align-items: center;
  justify-content: center;
  margin: 25px 0 -25px;
  .MuiInput-root,
  .MuiButtonBase-root,
  .MuiInput-underline:after {
    border-bottom-color: ${p => p.theme.colors.lightGreen};
    color: ${p => p.theme.colors.lightGreen};
  }
`;

const DailySummary = () => {
  const { t: translate } = useTranslation();
  const {
    state: { ordersByDate, loading },
    fetchOrdersByDate,
    calculateDailySummary,
    cleanUp
  } = useContext(OrderContext);
  const {
    state: { user }
  } = useContext(UserContext);

  const [date, setDate] = useState(new Date());
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!error && date) {
      fetchOrdersByDate(format(new Date(date), dateFormat));
    }
    return () => cleanUp();
  }, [cleanUp, date, error, fetchOrdersByDate]);

  const daliySummary = calculateDailySummary(ordersByDate);
  const handleDateChange = date => {
    if (date && !isValid(date)) {
      setError(true);
    } else {
      setError(false);
    }
    setDate(date);
  };

  const isPickerHidden = ![ROLES.ADMIN, ROLES.MANAGER].includes(user.role);

  return (
    <>
      <DatePickerWrapper hidden={isPickerHidden}>
        <DatePicker
          format={dateFormat}
          value={date}
          onChange={handleDateChange}
          error={error}
        />
      </DatePickerWrapper>
      <BaseTable
        loading={loading}
        headers={translate("dashboard.dailySummary.headers", {
          returnObjects: true
        })}
      >
        {Object.values(PAYMENT_METHOD).map(method => (
          <DailyRow
            key={method}
            title={translate(`payment.${method}`)}
            {...daliySummary[method]}
          />
        ))}
        <DailyRow title={translate("summary")} {...daliySummary.summary} bold />
      </BaseTable>
    </>
  );
};

export default DailySummary;
