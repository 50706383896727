import React, { useState, useEffect } from "react";
import DatePicker from "components/utils/date-picker";
import { isValid, format } from "date-fns";
import styled from "styled-components";
import { getEventLogs } from "utils/dataHandler";
import { Trans, useTranslation } from "react-i18next";

const dateFormat = "yyyy-MM-dd";

const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
  .MuiInput-root,
  .MuiButtonBase-root,
  .MuiInput-underline:after {
    border-bottom-color: ${p => p.theme.colors.lightGreen};
    color: ${p => p.theme.colors.lightGreen};
  }
`;

const LogsContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  font-size: 16px;
`;

const Log = styled.div`
  padding: 10px;
  border-bottom: 1px solid ${p => p.theme.colors.lightGreen};
  letter-spacing: 1.1px;
  line-height: 30px;
`;

const LogDate = styled.span`
  font-weight: bold;
  font-size: 17px;
  margin-right: 15px;
  color: ${p => p.theme.colors.primary};
`;

const HighlightedText = styled.span`
  font-weight: bold;
  font-size: 17px;
  color: ${p => p.theme.colors.lightGreen};
`;

const UpperText = styled(HighlightedText)`
  text-transform: uppercase;
  color: ${p => p.theme.colors.primary};
`;

const NoLog = styled.div`
  font-weight: bold;
  font-size: 17px;
  text-align: center;
  margin: 100px 0;
  color: ${p => p.theme.colors.primary};
`;

const EventLog = () => {
  const { t: translate } = useTranslation();
  const [logs, setLogs] = useState([]);

  const [date, setDate] = useState(new Date());
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!error && date) {
      getEventLogs(format(new Date(date), dateFormat)).then(logs =>
        setLogs(logs)
      );
    }
  }, [date, error]);

  const handleDateChange = date => {
    if (date && !isValid(date)) {
      setError(true);
    } else {
      setError(false);
    }
    setDate(date);
  };

  return (
    <>
      <DatePickerWrapper>
        <DatePicker
          format={dateFormat}
          value={date}
          onChange={handleDateChange}
          error={error}
        />
      </DatePickerWrapper>
      <LogsContainer>
        {logs.length > 0 ? (
          logs.map(log => (
            <Log key={log.id}>
              <Trans
                i18nKey="dashboard.eventLog.text"
                values={{
                  date: log.eventDateTime,
                  name: log.user.name,
                  orderId: log.order.id,
                  eventType: log.eventType,
                  from: log.payed !== null ? !log.payed : log.orderStateFrom,
                  to: log.payed !== null ? log.payed : log.orderStateTo
                }}
              >
                <LogDate>date</LogDate>
                ...
                <HighlightedText>name</HighlightedText>
                ...
                <HighlightedText>orderId</HighlightedText>
                ...
                <HighlightedText>field</HighlightedText>
                ...
                <UpperText>from</UpperText>
                ...
                <UpperText>to</UpperText>
                ...
              </Trans>
            </Log>
          ))
        ) : (
          <NoLog>{translate("dashboard.eventLog.noLog")}</NoLog>
        )}
      </LogsContainer>
    </>
  );
};

export default EventLog;
