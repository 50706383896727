import React from "react";
import { GlobalStyle, colors } from "style/globalStyle";
import styled, { ThemeProvider } from "styled-components";
import Header from "components/header";
import UserContextProvider from "contexts/UserContext";
import NotificationContextProvider from "contexts/NotificationContext";
import Footer from "components/footer";
import CartContextProvider from "contexts/CartContext";
import OrderContextProvider from "contexts/OrderContext";
import useScrollTop from "hooks/useScrollTop";
import { useLocation } from "react-router-dom";
import { OrderMonitor } from "components/dashboard";
import { NotificationContainer } from "components/utils";

const Main = styled.main`
  margin-top: ${p => (p.isHome ? 80 : 292)}px;
  @media (max-width: 1200px) {
    margin-top: 70px;
  }
`;

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  useScrollTop(pathname);

  const isHome = pathname === "/";

  return (
    <NotificationContextProvider>
      <OrderContextProvider>
        <UserContextProvider>
          <CartContextProvider>
            <ThemeProvider theme={{ colors }}>
              <GlobalStyle />
              <Header />
              <Main isHome={isHome}>{children}</Main>
              <Footer />
              <OrderMonitor />
              <NotificationContainer />
            </ThemeProvider>
          </CartContextProvider>
        </UserContextProvider>
      </OrderContextProvider>
    </NotificationContextProvider>
  );
};

export default Layout;
