import React, { useState, useContext } from "react";
import { TableRow, EqualTableColumn } from "style/global.styled";
import styled from "styled-components";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "moment/locale/hu";
import { ViewButton, BooleanSelector } from "components/utils";
import OrderDetails from "./order-details";
import StateSelector from "./state-selector";
import { OrderContext } from "contexts/OrderContext";
import { BOOLEAN_STATE } from "utils/contants";
import useWindowSize from "hooks/useWindowSize";

const CustomTableColumn = styled(EqualTableColumn)`
  :last-child {
    width: 100%;
    text-align: unset;
  }
`;

const ResponsiveRow = styled(TableRow)`
  ${CustomTableColumn} {
    width: 100%;
    margin: 10px 0;
    justify-content: space-between;
    header {
      font-weight: bold;
    }
    button {
      width: 100%;
    }
  }
`;

const PreOrder = styled.div`
  width: 100%;
  font-weight: bold;
  color: ${p => p.theme.colors.orange};
`;

const OrderRow = ({ order, isProfile }) => {
  const { t: translate, i18n } = useTranslation();
  const { modifyOrder } = useContext(OrderContext);

  const [viewDetails, setViewDetails] = useState(false);

  moment.locale(i18n.language);

  const {
    id,
    user,
    name,
    orderDate,
    state,
    payed,
    total,
    preOrderDate
  } = order;

  const open = () => {
    setViewDetails(!viewDetails);
  };

  const changeStatus = (name, value) => {
    const updatedOrder = { ...order, [name]: value };
    modifyOrder(updatedOrder);
  };

  const viewbutton = <ViewButton open={viewDetails} onClick={open} />;

  const orderdetails = <OrderDetails open={viewDetails} {...order} />;

  const paidSelector = isProfile ? (
    translate(`${BOOLEAN_STATE[payed]}`)
  ) : (
    <BooleanSelector
      name="payed"
      defaultValue={payed}
      onChange={changeStatus}
    />
  );

  const stateSelector = isProfile ? (
    translate(`orders.states.${state}`)
  ) : (
    <StateSelector defaultValue={state} onChange={changeStatus} />
  );

  const headers = translate("orders.headers", { returnObjects: true });

  const preOrder = preOrderDate ? (
    <PreOrder>{translate("orders.states.PREORDER")}</PreOrder>
  ) : (
    "-"
  );

  const columns = [
    `#${id}`,
    moment(new Date(orderDate)).format("LLL"),
    stateSelector,
    paidSelector,
    `${total} ${translate("forint")}`,
    viewbutton,
    orderdetails
  ];

  columns.splice(1, 0, user ? user.name : name);
  columns.unshift(preOrder);

  const { width } = useWindowSize();

  return width > 1000 ? (
    <TableRow>
      {columns.map((column, index) => (
        <CustomTableColumn key={index} totalColumn={columns.length - 1}>
          {column}
        </CustomTableColumn>
      ))}
    </TableRow>
  ) : (
    <ResponsiveRow>
      {columns.map((column, index) => (
        <CustomTableColumn key={index} totalColumn={columns.length - 1}>
          {index < headers.length - 1 && <header>{headers[index].text}</header>}
          {column}
        </CustomTableColumn>
      ))}
    </ResponsiveRow>
  );
};

export default OrderRow;
