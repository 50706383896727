import React, { useState } from "react";
import { Text, Container } from "style/global.styled";
import eventImg1 from "assets/images/fanyuvo-event1.jpg";
import eventImg2 from "assets/images/fanyuvo-event2.jpg";
import eventImg3 from "assets/images/fanyuvo-event3.jpg";
import styled from "styled-components";
import { ImageViewer } from "components/utils";
import { useTranslation } from "react-i18next";

const MainText = styled(Text)`
  margin: 30px auto;
  font-size: 25px;
  text-align: justify;
  font-weight: bold;
  @media (max-width: 1400px) {
    font-size: 18px;
  }
  @media (max-width: 900px) {
    font-size: 15px;
  }
`;

const Details = styled.div`
  max-width: 1450px;
  margin: 0 auto;
  @media (max-width: 1400px) {
    width: 100%;
    padding: 0 20px;
  }
  @media (max-width: 900px) {
    padding: 0;
  }
`;

const DetailSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
  :nth-child(odd) {
    flex-direction: row-reverse;
  }
  :nth-child(odd) {
    text-align: right;
  }
  img {
    width: 350px;
    cursor: zoom-in;
  }
  @media (max-width: 900px) {
    flex-direction: column !important;
    text-align: center !important;
    margin: 20px 0;
    img {
      width: 200px;
      margin: 30px 0;
    }
  }
`;

const Detail = styled(Text)`
  width: 80%;
  ${DetailSection}:nth-child(even) & {
    margin-left: 30px;
  }
  ${DetailSection}:nth-child(odd) & {
    margin-right: 30px;
  }
  ${DetailSection}:last-child & {
    line-height: 40px;
    text-align: justify;
  }
  h1 {
    font-size: 20px;
    margin: 0 0 20px;
  }
  & > div {
    margin: 12px 0;
  }
  @media (max-width: 1400px) {
    font-size: 16px;
    h1 {
      font-size: 18px;
    }
  }
  @media (max-width: 1024px) {
    ${DetailSection}:last-child & {
      line-height: 30px;
    }
    h1 {
      font-size: 15px;
    }
    & > div {
      font-size: 15px;
      margin: 10px 0;
    }
  }
  @media (max-width: 900px) {
    width: 100%;
    ${DetailSection}:nth-child(even) & {
      margin-left: 0;
    }
    ${DetailSection}:nth-child(odd) & {
      margin-right: 0;
    }
    ${DetailSection}:last-child & h1 {
      text-align: center;
    }
  }
`;

const Events = () => {
  const { t: translate } = useTranslation();

  const [open, setOpen] = useState(false);
  const [currImg, setCurrImg] = useState(0);

  const images = [eventImg1, eventImg2, eventImg3];

  const openViewer = imgIndex => {
    setCurrImg(imgIndex);
    setOpen(true);
  };

  const gotoNext = () => {
    setCurrImg(currImg + 1);
  };

  const gotoPrev = () => {
    setCurrImg(currImg - 1);
  };

  return (
    <Container>
      <Details>
        <MainText>{translate("events.mainText")}</MainText>
        {translate("events.details", { returnObjects: true }).map(
          (detail, index) => (
            <DetailSection key={index}>
              <img
                src={images[index]}
                alt={`fanyuvo-event${index}`}
                onClick={() => openViewer(index)}
              />
              <Detail>
                <h1>{detail.title}</h1>
                {detail.texts.map((text, index) => (
                  <div key={index}>{text}</div>
                ))}
              </Detail>
            </DetailSection>
          )
        )}
      </Details>
      <ImageViewer
        images={[eventImg1, eventImg2, eventImg3]}
        open={open}
        setNext={gotoNext}
        setPrev={gotoPrev}
        current={currImg}
        onClose={() => setOpen(false)}
      />
    </Container>
  );
};

export default Events;
