import React, { useContext } from "react";
import styled from "styled-components";
import { Button, Text } from "style/global.styled";
import { useTranslation } from "react-i18next";
import Product from "models/product.model";
import { CartContext } from "contexts/CartContext";
import { ALLERGEN_ICONS } from "utils/contants";

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  letter-spacing: 1px;
  border-top: ${props =>
    props.isFirst && `1px solid ${props.theme.colors.lightGreen}`};
  border-bottom: 1px solid ${props => props.theme.colors.lightGreen};
  @media (max-width: 650px) {
    min-width: unset;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Purchase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 650px) {
    width: 100%;
    flex-direction: row;
    align-items: flex-end;
    ${Text} {
      margin: 0;
    }
  }
`;

const Price = styled(Text)`
  text-align: right;
  font-size: 26px;
  font-family: EncSansBold;
  margin-bottom: 20px;
  @media (max-width: 650px) {
    font-size: 24px;
  }
`;

const AddToCartBtn = styled(Button)`
  margin: 0;
`;

const Texts = styled.div`
  max-width: 70%;
  align-self: center;
  ${Text} {
    text-align: left;
    font-size: 26px;
    font-family: EncSansBold;
  }
  ${Text}:nth-child(2) {
    font-size: 20px;
    margin-top: 20px;
    font-family: EncSans;
  }
  @media (max-width: 650px) {
    min-width: 100%;
    ${Text} {
      font-size: 24px;
    }
    ${Text}:nth-child(2) {
      font-size: 18px;
    }
  }
`;

const Allergens = styled.div`
  display: flex;
  align-self: flex-end;
  margin: 0 15px 0 auto;
  img {
    width: 25px;
    margin: 5px 4px;
  }
  @media (max-width: 650px) {
    margin: 10px 0 5px;
  }
`;

const ProductCard = props => {
  const { t: translate, i18n } = useTranslation();
  const { addProduct } = useContext(CartContext);

  const add = () => {
    const product = new Product(props);
    addProduct(product);
  };

  const lang = i18n.language;
  const {
    name,
    description,
    price,
    nameEnglish,
    descriptionEnglish,
    allergenics = [],
    orderable,
    isFirst
  } = props;

  return (
    <Card isFirst={isFirst}>
      <Texts>
        <Text>{lang === "hu" ? name : nameEnglish ? nameEnglish : name}</Text>
        {description && (
          <Text>
            {lang === "hu"
              ? description
              : descriptionEnglish
              ? descriptionEnglish
              : description}
          </Text>
        )}
      </Texts>
      <Allergens>
        {allergenics.map((allergen, index) => (
          <img key={index} src={ALLERGEN_ICONS[allergen]} alt={allergen} />
        ))}
      </Allergens>
      <Purchase>
        <Price>
          {price} {translate("forint")}
        </Price>
        <AddToCartBtn onClick={add} disabled={!orderable}>
          {orderable ? translate("addToCart") : translate("notOrderable")}
        </AddToCartBtn>
      </Purchase>
    </Card>
  );
};

export default ProductCard;
