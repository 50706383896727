import { useEffect } from "react";

const useKeyPress = eventHandler => {
  useEffect(() => {
    document.addEventListener("keydown", eventListener);
    return () => {
      document.removeEventListener("keydown", eventListener);
    };
  });

  const eventListener = e => {
    eventHandler(e.key);
  };
};

export default useKeyPress;
