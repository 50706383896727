import menu from "assets/pdf/fanyuvo_restaurant_menu_english.pdf";
import privacy from "assets/pdf/betyaros-adatkezelesi-tajekoztato.pdf";
import terms from "assets/pdf/betyaros-aszf.pdf";
import { ROLES } from "utils/contants";
import images from "assets/images";

export default {
  translation: {
    welcome1: "Welcome to the",
    welcome2: "Fanyűvő restaurant's webpage!",
    downloadableMenu: "Downloadable menu",
    menuPdf: menu,
    logoImg: images.LogoSmallEn,
    logoImgBig: images.LogoBigEn,
    navtexts: ["Home", "Home delivery", "Contact"],
    login: "Login",
    logout: "Logout",
    signup: "Sign Up",
    profile: "Profile",
    loggedIn: "Logged in",
    profileNavigation: ["Personal info", "Addresses", "Orders"],
    edit: "Edit",
    delete: "Delete",
    add: "Add",
    send: "Send",
    address: "Address",
    phone: "Phone",
    forint: "Huf",
    addToCart: "Add To Cart",
    notOrderable: "Not orderable",
    addNewAddress: "Add new address",
    aboutTitle: "About Us",
    chooseCategory: "Choose a category",
    chooseGroup: "Choose a group",
    chooseCategoryFirst: "Select category first",
    view: "View",
    close: "Close",
    yes: "Yes",
    no: "No",
    isNot: "No",
    desc: "descending",
    asc: "ascending",
    now: "Now",
    by: "by",
    noData: "There is currently no available data in this category!",
    summary: "Summary",
    requestInProgress: "Request in progress...",
    cardPaymentSoon: "Online card payment will be available soon!",
    aboutText:
      "Our guests are welcomed by wide variety of foods any drinks in a unique enviroment. You can find delicious tastes in the Hungarian and international cuisine hosted by our chef, Gabor Biro. If You want to have lunch, dinner or birthday celebrations, you will find what you need. If you are interested in buisness meetings, class reunions, fell free to come to us. Organise your wedding dinner at us; let the Fanyuvo be the starting location of your bachelor party.",
    splittedAboutText: [
      "Our guests are welcomed by wide variety of foods any drinks in a unique enviroment.",
      "You can find delicious tastes in the Hungarian and international cuisine hosted by our chef, Gabor Biro.",
      "If You want to have lunch, dinner or birthday celebrations, you will find what you need.",
      "If you are interested in buisness meetings, class reunions, fell free to come to us. Organise your wedding dinner at us; let the Fanyuvo be the starting location of your bachelor party."
    ],
    home: {
      title: "Traditional hungarian food",
      texts: [
        "Betyáros offers the best of traditional domestic and homemade flavors to those who are happy to linger over a bountiful meat platter, and not be intimidated by filling traditional comfort food either.",
        "Enjoy the nostalgic atmosphere of Sunday lunches and peaceful Hungarian cuisine in your home. We make your favorite dishes with heart and soul every day of the week from excellent ingredients and reliable quality."
      ]
    },
    error: {
      required: "Required field",
      emailInvalid: "Invalid email",
      emailExists: "This email is already exists",
      emailNotExists: "This email is not exists yet",
      weakPassword:
        "The password must contain minimum of 8 characters, at least 1 upper case, 1 lower case letter and 1 number",
      passwordsNotMatch: "Passwords do not match",
      invalidCoupon: "Invalid coupon",
      usedCoupon: "You can only validate one coupon at a time",
      discountNotValid: "The discount cannot be greater than 100%",
      invalidDate: "Invalid date",
      server: {
        title: "Server error!",
        text: "Something went wrong with the server, please try again later!"
      },
      loginError: "Wrong email or password",
      notVerified: "Email is not verified yet",
      noAccess: {
        title: "No Permission!",
        text: "You do not have permission to access this page!"
      },
      immutable: {
        email: "Email address cannot be changed currently!",
        personalInfo:
          "If you want to change your personal data, please do under the PROFILE menu!"
      },
      confrimation: {
        title: "Verified failed!",
        text:
          "Invalid identifier! Maybe you have already verified yourself or the identifier has expired."
      },
      forgotPw: {
        title: "Failed!",
        text: "This link is has already been used or expired!"
      },
      payment: {
        title: "Payment failed!",
        text:
          "You have entered incorrect card information or there is insufficient funds in your account"
      },
      order: {
        title: "Order out of service!",
        text:
          "Unfortunately, due to technical reasons, we had to stop orders! Thank you for your understanding!"
      },
      card: {
        cardNumberTooShort: "Card number is too short",
        notValidCard: "Card type is incorrect",
        wrongDate: "Incorrect expiry date",
        cvvTooShort: "Security code is too short"
      }
    },
    success: {
      registration: {
        title: "Successful registration!",
        text: "Please verify your email address via the link provided."
      },
      login: "Successfull login!",
      modification: {
        title: "Modified!",
        text: "Successfully modified your {{what}}!",
        data: "presonal info",
        password: "password"
      },
      added: {
        added: "Added!",
        address: "New address added!",
        cart: {
          title: "Added!",
          text: "You have successfully added the product to your cart."
        },
        user: {
          title: "Added!",
          text: "Verification link has been sent to the email address provided."
        }
      },
      deleted: {
        deleted: "Deleted!",
        address: "Your address deleted successfully!",
        cart: {
          title: "Deleted!",
          text: "You have successfully removed the product(s) from your cart."
        }
      },
      confrimation: {
        title: "Verified successfully!",
        text: "You have successfully verified your email address!"
      },
      redeemCoupon: {
        title: "Validated!",
        text: "You have successfully redeemed your coupon!"
      },
      order: {
        title: "Order placed!",
        text: "We have sent you an email about the success of your order."
      },
      newOrderReceived: "New order received!",
      modify: "Modified!"
    },
    form: {
      name: "Full name",
      email: "Email",
      phone: "Phone number",
      password: "Password",
      newPassword: "New password",
      confirmPassword: "Confirm password",
      forgotPw: "Forgot password",
      shippingAddress: "Shipping address",
      city: "City",
      address: "Street address",
      street: "Street",
      houseNumber: "Number, etc.",
      zipcode: "Zip Code",
      note: "Note for the order",
      selectAddress: "Select your address",
      noAddressSaved: "You don't have saved address",
      terms: [
        { text: "I accept the ", link: null },
        { text: "privacy policy", link: privacy },
        { text: " and the ", link: null },
        { text: "terms and conditions", link: terms }
      ]
    },
    personal: {
      myData: "My datas",
      changePassword: "Password change",
      editPassword: "Edit password"
    },
    forgotPw: {
      title: "New password request",
      infoText:
        "We will send you a link to your email address where you can change your password.",
      sentLinkText:
        "A link to change your password has been sent to the email address you provided."
    },
    map: {
      address: "Budapest, Vaci road 70.",
      texts: [
        "The restaurant is located 50 meters from the Dózsa György út stop of the M3 metro line: on the corner of Váci út and Tisza utca, next to the head office of Elektromos Művek (Hungarian Electric Works).",
        "We would like to inform our guests arriving by car that the street of the restaurant is a paid parking zone."
      ]
    },
    events: {
      mainText:
        "If You want to organise a birthday part, special occasions, press conferences, we will be here for You. Don't hesitate to call the manager Donat Lukacs at +36 70 452 8877 or +36 1 782 8450",
      details: [
        {
          title: "Infromations we need to know about the event:",
          texts: [
            "Your name.",
            "Your telephone number.",
            "Your exact number of guests.",
            "The date and the estimated preiod of Your staying.",
            "To order a specific menu or ordering a la carte.",
            "Any other special needs or requests."
          ]
        },
        {
          title: "There are meny separated halls in our restaurant",
          texts: [
            "Small hall 60 people",
            "Downstairs in front of the bar 30 people",
            "Downstairs great hall 70 people + stage",
            "Full basement at the same time 160 people"
          ]
        },
        {
          title: "Technical equipment:",
          texts: [
            "We have a local amplification in every separate halls in case of organising Your own programme. We broadcast sport events on the projector, feel free to use it, as well as our WiFi, just easily grab our notebook. Smaller events can be managed on our wooden stage. The dancing place can be increased by folding some tables in the great hall downstairs."
          ]
        }
      ]
    },
    contact: {
      info: [
        {
          title: "Home delivery",
          texts: [
            { bold: "Monday-Sunday: ", normal: "11 a.m. to 10 p.m." },
            {
              bold: "Areas: ",
              normal: "District XIII"
            },
            {
              bold: "Ordering methods: ",
              normal: "online or by phone: +36/70/3125125"
            },
            {
              bold: "Payment methods: ",
              normal: "cash, credit card or with OTP SZÉP card at the courier"
            }
          ]
        },
        {
          title: "Contacts",
          texts: [
            { bold: "E-mail: ", normal: "betyaros@betyaros.hu" },
            { bold: "Phone: ", normal: "+36/70/3125125" },
          ]
        },
        {
          title: "Company details",
          texts: [
            { normal: "1132 Budapest, Vaci road 70." },
            { bold: "Company: ", normal: "Blacko Kft." },
            { bold: "Company registration number: " },
            { normal: "01-09-731963" }
          ]
        }
      ],
    },
    cart: {
      title: "Shopping cart",
      headers: ["Product", "Quantity", "Price", "Total", "Remove"],
      emptyCart: "Your cart is currently empty.",
      goToMenu: "Go to the menu",
      couponCode: "Coupon code",
      redeem: "Redeem",
      subtotal: "Subtotal",
      delivery: "Delivery",
      discount: "Discount",
      totalCost: "Total cost",
      goToPayment: "Got to the payment",
      deliveryHelperText: "Charged after entering address",
      minOrderText: "The minimum order price is {{ price }} Huf.",
      deliveryTimeText:
        "The expected delivery time is about {{ time }} minutes."
    },
    payment: {
      shippingInfo: "Shipping information",
      method: "Payment method",
      cardTypes: "Card types",
      cardNumber: "Card number",
      cardHolderName: "Cardholder's name",
      cardExpire: "Expiry date (mm/yy)",
      cardCvv: "Security code",
      cardCvvHelp: "3-digit code on the back of the card",
      CASH: "Cash for the courier",
      CREDIT_CARD_ONLINE: "Card payment online",
      CREDIT_CARD: "Card payment at courier",
      sendOrder: "Send order",
      prevNotes: "Previous notes",
      orderDateTitle: "Date of delivery"
    },
    orders: {
      headers: [
        { text: "Pre-order", type: null },
        { text: "Id", type: "id" },
        { text: "Customer name", type: "name" },
        { text: "Date", type: "date" },
        { text: "Status", type: "status" },
        { text: "Paid", type: "paid" },
        { text: "Total Price", type: "price" },
        { text: "Details", type: null }
      ],
      orderDetails: "Order details",
      customerDetails: "Customer details",
      productName: "Product name",
      quantity: "Quantity",
      note: "Note",
      coupon: "Coupon code",
      payment: "Payment method",
      payed: "Paid",
      original: "Original price",
      discount: "Discount",
      delivery: "Shipping fee",
      total: "Total",
      name: "Name",
      email: "Email",
      phone: "Phone",
      address: "Address",
      states: {
        NEW: "New",
        PREPARING: "Preparing",
        DELIVERING: "Delivering",
        DELIVERED: "Delivered",
        PREORDER: "Pre-order"
      }
    },
    dashboard: {
      title: "Dashboard",
      nav: [
        {
          text: "Current orders",
          link: "current-orders",
          roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.SHOP_ASSISTANT]
        },
        {
          text: "Prepared orders",
          link: "done-orders",
          roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.SHOP_ASSISTANT]
        },
        {
          text: "Daily summary",
          link: "daily-summary",
          roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.SHOP_ASSISTANT]
        },
        {
          text: "All orders",
          link: "all-orders",
          roles: [ROLES.ADMIN, ROLES.MANAGER]
        },
        {
          text: "Coupons",
          link: "coupons",
          roles: [ROLES.ADMIN, ROLES.MANAGER]
        },
        {
          text: "Product handling",
          link: "edit-product",
          roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.SHOP_ASSISTANT]
        },
        {
          text: "Add Product",
          link: "add-product",
          roles: [ROLES.ADMIN, ROLES.MANAGER]
        },
        {
          text: "Order price",
          link: "min-order-price",
          roles: [ROLES.ADMIN, ROLES.MANAGER]
        },
        {
          text: "Delivery time",
          link: "delivery-time",
          roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.SHOP_ASSISTANT]
        },
        {
          text: "Event log",
          link: "event-log",
          roles: [ROLES.ADMIN, ROLES.MANAGER]
        },
        {
          text: "Users",
          link: "users",
          roles: [ROLES.ADMIN]
        },
        {
          text: "Add User",
          link: "add-user",
          roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.SHOP_ASSISTANT]
        }
      ],
      users: {
        headers: [
          { text: "Id", type: "id" },
          { text: "Registration", type: "date" },
          { text: "Name", type: "name" },
          { text: "Email", type: "email" },
          { text: "Role", type: "role" },
          { text: "More details", type: null }
        ],
        roles: {
          ADMIN: "Admin",
          MANAGER: "Manager",
          SHOP_ASSISTANT: "Shop assistant",
          CUSTOMER: "Customer"
        },
        phone: "Phone number",
        addresses: "Saved addresses",
        chooseRole: "Choose role",
        addUser: "Add user"
      },
      orderMonitor: {
        title: "Today orders",
        NEW: "New orders",
        PREPARING: "Orders in progress",
        DELIVERING: "Orders under delivery",
        DELIVERED: "Delivered orders"
      },
      coupons: {
        headers: [
          { text: "Code", type: null },
          { text: "Discount(%)", type: null },
          { text: "Active", type: null }
        ],
        titles: {
          addNew: "Add new coupon",
          allCoupon: "All coupon"
        }
      },
      products: {
        headers: [
          { text: "Name", type: "nameE" },
          { text: "Category", type: "type" },
          { text: "Group", type: "group" },
          { text: "Orderable", type: "orderable" },
          { text: "", type: null }
        ]
      },
      dailySummary: {
        headers: [
          { text: "Group" },
          { text: "Orders number" },
          { text: "Unpaid orders number" },
          { text: "Unpaid price" },
          { text: "Received price" }
        ]
      },
      orderActivity: {
        title: "Orders",
        off: "Off",
        on: "On",
        helperText:
          "Use this switch to stop or start orders. Use only when necessary!"
      },
      orderInfo: {
        minOrderTitle: "Minimum order price",
        minOrderLabel: "huf",
        actualPrice: "Current: {{ price }} Huf",
        deliveryTimeTitle: "Delivery time",
        deliveryTimeLabel: "minute",
        actualTime: "Current: {{ time }} minutes"
      }
    },
    footer: {
      contactsTitle: "Contacts",
      contacts: [
        "betyaros@betyaros.hu",
        "+36/70/3125125",
        "Follow us on Facebook!"
      ],
      openingHoursTitle: "Home delivery",
      openingHours: [
        "Monday-Sunday: 11:00 - 22:00"
      ],
      copyrightTexts: [
        { text: "© 2020 Betyáros", link: null },
        { text: "All rights reserved", link: null },
        { text: "Privacy Policy", link: privacy },
        { text: "Terms and Conditions", link: terms }
      ]
    },
    products: {
      details: {
        name: "Product name",
        nameEnglish: "Product name in english",
        description: "Product description",
        descriptionEnglish: "Product description in english",
        price: "Product price (Huf)"
      },
      types: {
        FOOD: "Food",
      },
      orderable: "Orderable",
      notOrderable: "Not orderable",
      allergensTitle: "Allergens",
      chooseOrderable: "Select whether orderable",
      addNew: "Add new product",
      edit: "Edit product",
      emptyProducts: "There is currently no such product",
      groups: {
        STARTER: "Appetisers",
        SOUP: "Soups",
        POULTRY: "Poultry dishes",
        FISH: "Fish dishes",
        PORK: "Pork dishes",
        BEEF: "Beef dishes",
        WOODEN_PLATE: "Dish of “Betyáros” on wooden plate",
        GARNISH: "Garnish",
        PICKLE: "Pickles",
        DESSERT: "Desserts",
      }
    },
    foods: {
      choosableCategories: "Optional categories",
      categories: [
        { name: "Appetisers", group: "appetisers" },
        { name: "Soups", group: "soups" },
        { name: "Poultry dishes", group: "poultry" },
        { name: "Fish dishes", group: "fish" },
        { name: "Pork dishes", group: "pork" },
        { name: "Beef dishes", group: "beef" },
        {
          name: "Dish of “Betyáros” on wooden plate",
          group: "betyaros-dishes"
        },
        { name: "Garnish", group: "garnishes" },
        { name: "Pickles", group: "pickles" },
        { name: "Desserts", group: "desserts" }
      ],
      allergens: ["Gluten free", "Lactose free", "Hot", "Vegetarian"],
      appetisers: {
        title: "Appetisers",
      },
      soups: {
        title: "Soups",
      },
      poultry: {
        title: "Poultry dishes",
      },
      fish: {
        title: "Fish dishes",
      },
      pork: {
        title: "Pork dishes",
      },
      beef: {
        title: "Beef dishes",
      },
      "betyaros-dishes": {
        title: "Dish of “Betyáros” on wooden plate",
      },
      garnishes: {
        title: "Garnish",
      },
      pickles: {
        title: "Pickles",
      },
      desserts: {
        title: "Desserts",
      }
    },
  }
};
