import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Link, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TextField, LoaderButton } from "components/utils";
import { ErrorMsg } from "style/global.styled";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "contexts/UserContext";
import useFormValues from "hooks/useFormValues";

const Title = styled.div`
  font-size: 40px;
  margin: 30px 0;
  font-family: SpicyRice;
  color: ${props => props.theme.colors.primary};
`;

const ButtonCont = styled.div`
  display: flex;
  align-items: center;
`;

const ForgotPwBtn = styled(Link)`
  margin: 0 30px;
  font-family: EncSansMed;
  color: ${props => props.theme.colors.lightGreen};
  :hover {
    text-decoration: underline;
  }
`;

const Error = styled(ErrorMsg)`
  display: ${props => !props.error && "none"};
  margin-bottom: 10px;
`;

const Login = () => {
  const { t: translate } = useTranslation();
  const {
    logIn,
    state: { isLoggedIn }
  } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const init = { email: "", password: "" };

  const [error, setError] = useState("");
  const [values, changeHandler] = useFormValues(init);

  const handleChange = e => {
    if (error) setError("");
    changeHandler(e);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    logIn(values).catch(err => {
      setLoading(false);
      setError(err);
    });
  };

  if (isLoggedIn) return <Redirect to="/profile" />;

  return (
    <div>
      <Title>{translate("login")}</Title>
      <form onSubmit={handleSubmit}>
        <TextField
          type="text"
          name="email"
          label={translate("form.email")}
          value={values.email}
          icon={faEnvelope}
          onChange={handleChange}
          error={!!error}
          required
        />
        <TextField
          type="password"
          name="password"
          label={translate("form.password")}
          value={values.password}
          icon={faLock}
          onChange={handleChange}
          error={!!error}
          required
        />
        <Error error={error}>{translate(`error.${error}`)}</Error>
        <ButtonCont>
          <LoaderButton loading={loading} text={translate("login")} />
          <ForgotPwBtn to="/user/forgot-password">
            {translate("form.forgotPw")}
          </ForgotPwBtn>
        </ButtonCont>
      </form>
    </div>
  );
};

export default Login;
