import React from "react";
import { Container } from "style/global.styled";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Cont = styled(Container)`
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: grid;
  justify-content: center;
  width: 80%;
  margin: 30px auto;
  min-width: 1300px;
  letter-spacing: 1px;
  grid-template-columns: 440px repeat(2, 320px);
  @media (max-width: 1300px) {
    min-width: 900px;
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 900px) {
    width: 100%;
    min-width: unset;
    grid-template-columns: 1fr;
    margin: 0 auto;
  }
`;

const Title = styled.div`
  font-size: 23px;
  margin-bottom: 15px;
  color: ${props => props.theme.colors.primary};
  font-family: SpicyRice;
`;

const InfoContainer = styled.div`
  padding: 20px;
  min-width: 230px;
  position: relative;
  :after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: 2px;
    height: 60%;
    transform: translateY(-50%);
    background: ${props => props.theme.colors.lightGreen};
  }
  :last-child:after {
    display: none;
  }
  @media (max-width: 1300px) {
    :nth-child(3):after {
      display: none;
    }
  }
  @media (max-width: 900px) {
    :nth-child(2):after {
      display: none;
    }
    :nth-child(3):after {
      display: initial;
    }
    :nth-child(4):after {
      display: none;
    }
    :last-child:after {
      display: initial;
    }
  }
  @media (max-width: 900px) {
    padding: 25px 10px;
    text-align: center;
    :after {
      display: none !important;
    }
  }
`;

const InfoText = styled.div`
  margin: 5px 0;
  font-size: 20px;
  color: ${props => props.theme.colors.lightGreen};
  span {
    color: ${props => props.theme.colors.primary};
    font-family: EncSansBold;
  }
`;

const Contact = () => {
  const { t: translate } = useTranslation();

  return (
    <Cont>
      <Wrapper>
        {translate("contact.info", { returnObjects: true }).map(
          (info, index) => (
            <InfoContainer key={index}>
              <Title>{info.title}</Title>
              {info.texts.map((text, index) => (
                <InfoText key={index}>
                  {text.bold && <span>{text.bold}</span>}
                  {text.normal}
                </InfoText>
              ))}
            </InfoContainer>
          )
        )}
      </Wrapper>
    </Cont>
  );
};

export default Contact;
