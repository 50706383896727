import React, { useEffect, useState } from "react";
import { getUserOrderNotes } from "utils/dataHandler";
import { TextField } from "components/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PreviousNotes from "./previous-notes";

const Note = styled.div`
  position: relative;
`;

const PreviousNotesBtn = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  color: ${p => p.theme.colors.lightGreen};
  :hover {
    text-decoration: underline;
  }
`;

const NoteField = ({ value, handleChange, setNote }) => {
  const { t: translate } = useTranslation();
  const [notes, setNotes] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getUserOrderNotes().then(notes => setNotes(notes));
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNoteClick = note => {
    setNote(note);
    handleClose();
  };

  return (
    <Note>
      <TextField
        name="note"
        type="textarea"
        label={translate("form.note")}
        value={value}
        onChange={handleChange}
      />
      {notes.length > 0 && (
        <PreviousNotesBtn onClick={handleOpen}>
          {translate("payment.prevNotes")}
        </PreviousNotesBtn>
      )}
      <PreviousNotes
        open={open}
        notes={notes}
        onClose={handleClose}
        onClick={handleNoteClick}
      />
    </Note>
  );
};

export default NoteField;
