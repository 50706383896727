import React from "react";
import { Button } from "style/global.styled";
import Loader from "../loader/Loader";

const LoaderButton = ({ className, loading, type, text }) => {
  return (
    <Button className={className} type={type} disabled={loading}>
      {!loading ? text : <Loader color="light" size="25px" />}
    </Button>
  );
};

export default LoaderButton;
