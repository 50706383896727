import React, { useState } from "react";
import { Switch, FormLabel, FormHelperText } from "@material-ui/core";
import styled from "styled-components";
import { Text } from "style/global.styled";
import { setOrdersActivity, getOrdersActivity } from "utils/dataHandler";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Container = styled.div`
  width: 90%;
  margin: 30px auto 0;
  ${Text} {
    font-weight: bold;
  }
`;

const Switcher = styled.div`
  display: flex;
  align-items: center;
`;

const CustomSwitch = styled(Switch)`
  .Mui-checked + .MuiSwitch-track {
    background-color: ${p => p.theme.colors.primary} !important;
  }
  .Mui-checked {
    color: ${p => p.theme.colors.primary} !important;
  }
`;

const Wrapper = styled.div`
  max-width: 300px;
`;

const OrderActivator = () => {
  const { t: translate } = useTranslation();
  const { pathname } = useLocation();
  const [active, setActive] = useState(true);

  useEffect(() => {
    getOrdersActivity().then(active => setActive(active));
  }, [pathname]);

  const handleChange = () => {
    setOrdersActivity().then(active => {
      setActive(active);
    });
  };

  const messages = translate("dashboard.orderActivity", {
    returnObjects: true
  });

  return (
    <Container>
      <Wrapper>
        <FormLabel>{messages.title}</FormLabel>
        <Switcher>
          <Text>{messages.off}</Text>
          <CustomSwitch checked={active} onChange={handleChange} />
          <Text>{messages.on}</Text>
        </Switcher>
        <FormHelperText>{messages.helperText}</FormHelperText>
      </Wrapper>
    </Container>
  );
};

export default OrderActivator;
