import React from "react";
import ReactDOM from "react-dom";
import {
  Confirm,
  Products,
  Contact,
  Events,
  Profile,
  Auth,
  Home,
  Cart,
  Map,
  Payment,
  Dashboard,
  SuccessCardPayment
} from "pages";
import ForgotPassword from "pages/ForgotPassword";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { ROLES } from "utils/contants";
import { PrivateRoute, PublicRoute } from "components/utils";
import Layout from "components/layout";

import "./i18n";

const { ADMIN, MANAGER, SHOP_ASSISTANT } = ROLES;

const routes = (
  <Router>
    <Layout>
      <Switch>
        <Route
          path="/user/forgot-password/:token?"
          component={ForgotPassword}
        />
        <Route path="/user/confirmation/:token" component={Confirm} />
        <Route
          exact
          path="/foods"
          render={() => <Redirect to="/foods/appetisers" />}
        />
        <Route path="/foods/:group" component={Products} />
        <Route path="/contact" component={Contact} />
        <Route path="/events" component={Events} />
        <Route path="/map" component={Map} />
        <Route path="/cart" component={Cart} />
        <PublicRoute exact path="/pay" component={Payment} />
        <PublicRoute
          path="/pay/card-payment/successful"
          component={SuccessCardPayment}
        />
        <PrivateRoute path="/profile/:subpage?/:add?" component={Profile} />
        <PrivateRoute path="/login" component={Auth} />
        <PrivateRoute
          path="/dashboard/:subpage?"
          component={Dashboard}
          roles={[ADMIN, MANAGER, SHOP_ASSISTANT]}
        />
        <Route exact path="/" component={Home} />
        <Redirect to="/" />
      </Switch>
    </Layout>
  </Router>
);

ReactDOM.render(routes, document.getElementById("root"));
