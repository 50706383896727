import React from "react";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import Navbar from "./navbar";
import images from "assets/images";

const HeaderContainer = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background-image: url(${images.BackgroundRed});
  background-attachment: fixed;
  ${p =>
    !p.isHomePage &&
    css`
      border-bottom: 1px solid #fff;
      box-shadow: 0 0 30px ${p => p.theme.colors.primary};
    `}
`;

const HeaderImageWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const Header = () => {
  const { pathname } = useLocation();

  const isHomePage = pathname === "/";

  return (
    <HeaderContainer isHomePage={isHomePage}>
      {!isHomePage && (
        <HeaderImageWrapper>
          <img src={images.LogoHeader} alt="Logo" />
        </HeaderImageWrapper>
      )}
      <Navbar isHomePage={isHomePage} />
    </HeaderContainer>
  );
};

export default Header;
