import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Container, Text } from "style/global.styled";
import images from "assets/images";

const Page = styled.div`
  background: url(${images.BackgroundLight});
`;

const Wrapper = styled(Container)`
  padding-right: 30px;
  padding-left: 30px;
  letter-spacing: 1px;
  background: unset;
  @media (max-width: 600px) {
    padding: 10px 20px;
  }
`;

const TextContainer = styled.div`
  text-align: justify;
  ${Text} {
    margin: 30px 0;
    max-width: 70%;
    span {
      font-weight: bold;
    }
    @media (max-width: 1024px) {
      max-width: 100%;
    }
  }
`;

const MapContainer = styled.div`
  width: 100%;
  height: 70vh;
`;

const Map = () => {
  const { t: translate, i18n } = useTranslation();
  const lng = i18n.language;

  return (
    <Page>
      <Wrapper>
        <TextContainer>
          <Text>
            <div>
              <span>{translate("address")}: </span>
              {translate("map.address")}
            </div>
            <div>
              <span>{translate("phone")}.: </span>
              {translate("map.phone")}
            </div>
          </Text>
          {translate("map.texts", { returnObjects: true }).map(
            (text, index) => (
              <Text key={index}>{text}</Text>
            )
          )}
        </TextContainer>
      </Wrapper>
      <MapContainer>
        <iframe
          title="map-fanyuvo"
          id="map-fanyuvo"
          width="100%"
          height="100%"
          src={`https://maps.google.com/maps?hl=${lng}&q=fany%C5%B1v%C5%91%20%C3%A9tterem&t=&z=16&ie=UTF8&iwloc=&output=embed`}
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
        ></iframe>
      </MapContainer>
    </Page>
  );
};

export default Map;
