import menu from "assets/pdf/fanyuvo_etterem_etlap.pdf";
import privacy from "assets/pdf/betyaros-adatkezelesi-tajekoztato.pdf";
import terms from "assets/pdf/betyaros-aszf.pdf";
import { ROLES } from "utils/contants";
import images from "assets/images";

export default {
  translation: {
    welcome1: "Üdvözöljük a",
    welcome2: "Fanyűvő étterem weboldalán!",
    downloadableMenu: "Letölthető étlap",
    menuPdf: menu,
    logoImg: images.LogoSmall,
    logoImgBig: images.LogoBig,
    navtexts: ["Kezdőlap", "Házhoz szállítás", "Kapcsolat"],
    login: "Belépés",
    logout: "Kijelentkezés",
    signup: "Regisztráció",
    profile: "Profil",
    loggedIn: "Belépve",
    profileNavigation: ["Személyes adatok", "Címek", "Rendelések"],
    edit: "Módosítás",
    delete: "Törlés",
    add: "Hozzáadás",
    send: "Küldés",
    address: "Cím",
    phone: "Tel",
    forint: "Ft",
    addToCart: "Kosárba",
    notOrderable: "Nem rendelhető",
    fanyuvoEmail: "fanyuvo@fanyuvo.hu",
    addNewAddress: "Új cím hozzáadása",
    aboutTitle: "Rólunk",
    chooseCategory: "Válassz kategóriát",
    chooseGroup: "Válassz csoportot!",
    chooseCategoryFirst: "Válasszon elöbb kategóriát!",
    view: "Megtekintés",
    close: "Bezárás",
    yes: "Igen",
    no: "Nem",
    isNot: "Nincs",
    desc: "csökkenő",
    asc: "növekvő",
    now: "Most",
    by: "szerint",
    noData: "Jelenleg nincs elérhető adat ebben a kategóriában!",
    summary: "Összegzés",
    requestInProgress: "Lekérés folyamatban...",
    cardPaymentSoon: "Hamarosan elérhető lesz az online kártyás fizetés!",
    aboutText:
      "A Fanyűvő könnyen elérhető helyen, a Dózsa György út metrómegállótól egy percre, a Váci út 70. szám alatt várja vendégeit. Konyhafőnökünk, Bíró Gábor a változatos és karakteres magyar ízvilágból merítve kínál különleges tálakat a hazai konyhakultúra kedvelőinek, és ínyenc fogásokat a nemzetközi konyhaművészet klasszikus ételeiből. Legyen szó mindennapi vagy alkalmi ebédről, vacsoráról, társasági összejövetelről, céges csapatépítésről, osztálytalálkozóról vagy ünnepi alkalomról, - a születésnaptól a lány- és legénybúcsún át egészen az esküvői vacsoráig - mi hangulatos, tágas enteriőrrel és otthonos kiszolgálással kedveskedünk.",
    splittedAboutText: [
      "A Fanyűvő könnyen elérhető helyen, a Dózsa György út metrómegállótól egy percre, a Váci út 70. szám alatt várja vendégeit.",
      "Konyhafőnökünk, Bíró Gábor a változatos és karakteres magyar ízvilágból merítve kínál különleges tálakat a hazai konyhakultúra kedvelőinek, és ínyenc fogásokat a nemzetközi konyhaművészet klasszikus ételeiből.",
      "Legyen szó mindennapi vagy alkalmi ebédről, vacsoráról, társasági összejövetelről, céges csapatépítésről, osztálytalálkozóról vagy ünnepi alkalomról, - a születésnaptól a lány- és legénybúcsún át egészen az esküvői vacsoráig - mi hangulatos, tágas enteriőrrel és otthonos kiszolgálással kedveskedünk.",
      ""
    ],
    cities: ["Budapest"],
    home: {
      title: "A betyár mindenit, de finom!",
      texts: [
        "A Betyáros a hagyományos hazai és házias ízvilág legjavát kínálja azoknak, akik örömmel időznek egy minden földi jóval teli hústál felett, és nem rettenek meg a laktató klasszikusoktól sem.",
        "Élvezd otthonodban a vasárnapi ebédek és a békebeli magyar konyha nosztalgikus hangulatát! Mi a hét minden napján kiváló alapanyagokból, megbízható minőségben, és ami a legfontosabb: szívvel-lélekkel készítjük kedvenc ételeidet."
      ]
    },
    error: {
      required: "Kötelező mező",
      emailInvalid: "Helytelen email cím",
      emailExists: "Erről az email címről már regisztráltak",
      emailNotExists: "Ezzel email címmel még nem regisztráltak",
      weakPassword:
        "A jelszónak legalább 8 karakterből kell állnia, tartalmaznia kell legalább 1 kis-, és 1 nagybetűt, valamint 1 számot",
      passwordsNotMatch: "A jelszavak nem egyeznek",
      invalidCoupon: "Érvénytelen kupon",
      usedCoupon: "Egyszerre csak egy kupont érvényesíthet",
      discountNotValid: "Az engedmény nem lehet nagyobb 100%-nál",
      invalidDate: "Érvénytelen dátum",
      server: {
        title: "Szerver hiba!",
        text: "Valami hiba történt a szerverrel, kérjük próbálja újra késöbb!"
      },
      loginError: "Hibás email cím vagy jelszó",
      notVerified: "Email cím még nincs hitelesítve",
      noAccess: {
        title: "Jogosultság hiány!",
        text: "Nincs hozzáférrési jogosultságod az oldal eléréséhez!"
      },
      immutable: {
        email: "E-mail cím jelenleg nem módosítható!",
        personalInfo:
          "Kérjük, hogy amennyiben módosítani szeretné személyes adatait azt a PROFIL menüpont alatt tegye meg!"
      },
      confrimation: {
        title: "Sikertelen hitelesítés!",
        text:
          "Helytelen azonosító! Lehetséges, hogy már hitelesítette magát vagy az azonosító lejárt!"
      },
      forgotPw: {
        title: "Sikertelen!",
        text: "Ezt a linket már felhasználták vagy lejárt!"
      },
      payment: {
        title: "Fizetés sikertelen!",
        text:
          "Hibás kártyaadatokat adott meg vagy nincs elegendő fedezet a számláján"
      },
      order: {
        title: "Rendelés üzemen kívül!",
        text:
          "Technikai okok miatt sajnos le kellett állítanunk a rendeléseket! Megértését köszönjük!"
      },
      card: {
        cardNumberTooShort: "Kártyaszám túl rövid",
        notValidCard: "Kártya típusa nem megfelelő",
        wrongDate: "Helytelen lejárati idő",
        cvvTooShort: "Biztonsági kód túl rövid"
      }
    },
    success: {
      registration: {
        title: "Sikeres regisztráció!",
        text: "Kérjük hitelesítse email címét a kiküldött linken keresztül."
      },
      login: "Sikeres bejelentkezés!",
      modification: {
        title: "Módosítva!",
        text: "Sikeresen módosította {{what}}!",
        data: "az adatait",
        password: "a jelszavát"
      },
      added: {
        added: "Hozzáadva!",
        address: "Új cím hozzáadva!",
        cart: {
          title: "Hozzáadva!",
          text: "A terméket sikeresen hozzáadta a koráshoz."
        },
        user: {
          title: "Hozzáadva!",
          text: "A hitelesítő link el lett küldve a megadott email címre."
        }
      },
      deleted: {
        deleted: "Törölve!",
        address: "Címét sikeresen törölte!",
        cart: {
          title: "Eltávoltítva!",
          text: "A termék(ek)et sikeresen eltávolította a kosárból."
        }
      },
      confrimation: {
        title: "Sikeres hitelesítés!",
        text: "Ön sikeresen hitelesítette email címét!"
      },
      redeemCoupon: {
        title: "Érvényesítve!",
        text: "A kuponodat sikeresen beváltottad!"
      },
      order: {
        title: "Rendelés leadva!",
        text: "Rendelése sikerességéről egy email-t küldtünk önnek."
      },
      newOrderReceived: "Új rendelés érekezett!",
      modify: "Módosítva!"
    },
    form: {
      name: "Teljes név",
      email: "E-mail cím",
      phone: "Telefonszám",
      password: "Jelszó",
      newPassword: "Új jelszó",
      confirmPassword: "Jelszó megerősítés",
      forgotPw: "Elfelejtettem a jelszavam",
      shippingAddress: "Szállítási adatok",
      city: "Város",
      address: "Utca, házszám",
      street: "Utca",
      houseNumber: "Házszám, stb.",
      zipcode: "Irányítószám",
      note: "Megjegyzés a rendeléshez",
      selectAddress: "Válaszd ki a címed",
      noAddressSaved: "Nincs címed elmentve",
      terms: [
        { text: "Elfogadom az ", link: null },
        { text: "adatkezelési tájékoztatót", link: privacy },
        { text: " és az ", link: null },
        { text: "általános szerződési feltételeket", link: terms }
      ]
    },
    personal: {
      myData: "Adataim",
      changePassword: "Jelszó csere",
      editPassword: "Jelszó módosítás"
    },
    forgotPw: {
      title: "Új jelszó igénylés",
      infoText:
        "E-mail címére kiküldünk egy linket, amin keresztül módosítani tudja jelszavát.",
      sentLinkText:
        "A jelszó módosításához szükséges linket elküldtük a megadott email címre."
    },
    map: {
      address: "Budapest, XIII. Váci út 70.",
      phone: "+36/1/782-84-50",
      texts: [
        "Az étterem az M3-as metróvonal Dózsa György út megállójától 50 méterre található: a Váci út és a Tisza utca sarkán, az Elektromos Művek központja mellett.",
        "Felhívjuk autóval érkező vendégeink figyelmét, hogy éttermünk utcája is a fizetős parkoló övezetbe tartozik!"
      ]
    },
    events: {
      mainText:
        "Amennyiben szívesen rendezné nálunk születésnapi-, névnapi-, céges buliját, osztálytalálkozóját, sajtótájékoztatóját, állófogadást, Mi szívesen állunk rendelkezésére. Kérjük keresse telefonon Lukácsa Donát üzletvezetőnket a +36/70/452-8877, vagy a +36/1/782-84-50 számon.",
      details: [
        {
          title:
            "Amit Mi biztosan tudni szeretnénk a redezvény zökkenőmentes lebonyolításához:",
          texts: [
            "Az Ön nevét.",
            "Az Ön telefonszámát.",
            "Várhatóan hányan érkeznek.",
            "Mikorra várhatók, és előreláthatólag meddig maradnak (milyen napokon).",
            "Az étterem melyik részében lennének, esetleg az egészen.",
            "A konyhánk készüljön-e külön az Önök érkezésére, vagy étlapról rendelnek.",
            "Bármilyen egyéb kérése van, kérjük szintén jelezze."
          ]
        },
        {
          title: "Éttermünkben több különterem is foglalható:",
          texts: [
            "Kisterem: 60 fő",
            "Pincében, a pult előtti terem: 30 fő",
            "Nagyterem: 70 fő + színpad",
            "Teljes pince egyben: 160 fő"
          ]
        },
        {
          title: "Technikai felszereltség:",
          texts: [
            "Minden teremben hangosítással rendelkezünk, így bármilyen kérést teljesíteni tudunk, ha egy különteremben, saját műsort szeretnének. Igény szerint kivetítőn követheti a sporteseményeket, vagy más műsorokat, önálló tartalmat is vetíthet. Ha számítógépről szeretne bemutatót tartani, megteheti. Fával burkolt színpadunkon lehetőség szerint kisebb technikai igényű rendezvényeket meg tudunk oldani. A nagyteremben igény szerint növelni tudjuk a táncteret az asztalok felhajtásával."
          ]
        }
      ]
    },
    contact: {
      info: [
        {
          title: "Házhoz szállítás",
          texts: [
            { bold: "Hétfő-Vasárnap: ", normal: "11:00-22:00" },
            {
              bold: "Kiszállítási terület: ",
              normal: "XIII. kerület"
            },
            {
              bold: "Rendelési módok: ",
              normal: "online vagy telefonon: +36/70/3125125"
            },
            {
              bold: "Fizetési módok: ",
              normal: "készpénz, bankkártya vagy OTP SZÉP-kártya a futárnál"
            }
          ]
        },
        {
          title: "Elérhetőségek",
          texts: [
            { bold: "E-mail: ", normal: "betyaros@betyaros.hu" },
            { bold: "Telefon: ",  normal: "+36/70/3125125" },
          ]
        },
        {
          title: "Cégadatok",
          texts: [
            { normal: "1132 Budapest, Váci út 70." },
            { bold: "Cégnév: ", normal: "Blacko Kft." },
            { bold: "Cégjegyzékszám: ", normal: "01-09-731963" },
          ]
        }
      ],
    },
    cart: {
      title: "Kosár tartalma",
      headers: ["Termék", "Mennyiség", "Ár", "Összeg", "Törlés"],
      emptyCart: "Jelenleg üres a kosarad.",
      goToMenu: "Irány az étlap",
      couponCode: "Kupon kód",
      redeem: "Beváltás",
      subtotal: "Részösszeg",
      delivery: "Szállítás",
      discount: "Kedvezmény",
      totalCost: "Fizetendő",
      goToPayment: "Tovább a pénztárhoz",
      deliveryHelperText: "Cím megadása után kerül felszámításra",
      minOrderText: "A minimális rendelési összeg {{ price }} Ft.",
      deliveryTimeText: "A várható szállítási idő kb. {{ time }} perc."
    },
    payment: {
      shippingInfo: "Szállítási adatok",
      method: "Fizetés módja",
      cardTypes: "Kártya típusok",
      cardNumber: "Kártyaszám",
      cardHolderName: "Kártyatulajdonos neve",
      cardExpire: "Lejárati idő (hh/éé)",
      cardCvv: "Biztonsági kód",
      cardCvvHelp: "Kártya hátulján szereplő 3 számjegyű kód",
      CASH: "Készpénzben a futárnak",
      CREDIT_CARD_ONLINE: "Kártyás fizetés online",
      CREDIT_CARD: "Kártyás fizetés futárnál",
      sendOrder: "Rendelés elküldése",
      prevNotes: "Korábbi megyjegyzések",
      orderDateTitle: "Kiszállítás időpontja"
    },
    orders: {
      headers: [
        { text: "Előrendelés", type: null },
        { text: "Azonosító", type: "id" },
        { text: "Rendelő neve", type: "name"},
        { text: "Dátum", type: "date" },
        { text: "Állapot", type: "status" },
        { text: "Fizetve", type: "paid" },
        { text: "Összeg", type: "price" },
        { text: "Részletek", type: null }
      ],
      orderDetails: "Rendelés adatai",
      customerDetails: "Rendelő adatai",
      productName: "Termék neve",
      quantity: "Mennyiség",
      note: "Megjegyzés",
      coupon: "Kupon kód",
      payment: "Fizetés módja",
      payed: "Fizetve",
      original: "Eredeti ár",
      discount: "Kedvezmény",
      delivery: "Szállítási díj",
      total: "Végösszeg",
      name: "Név",
      email: "E-mail",
      phone: "Telefonszám",
      address: "Cím",
      states: {
        NEW: "Új",
        PREPARING: "Készítés alatt",
        DELIVERING: "Szállítás alatt",
        DELIVERED: "Kiszállítva",
        PREORDER: "Előrendelés"
      }
    },
    dashboard: {
      title: "Irányítópult",
      nav: [
        {
          text: "Aktuális rendelések",
          link: "current-orders",
          roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.SHOP_ASSISTANT]
        },
        {
          text: "Elkészített rendelések",
          link: "done-orders",
          roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.SHOP_ASSISTANT]
        },
        {
          text: "Napi összegzés",
          link: "daily-summary",
          roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.SHOP_ASSISTANT]
        },
        {
          text: "Összes rendelés",
          link: "all-orders",
          roles: [ROLES.ADMIN, ROLES.MANAGER]
        },
        {
          text: "Kuponok",
          link: "coupons",
          roles: [ROLES.ADMIN, ROLES.MANAGER]
        },
        {
          text: "Termékek kezelése",
          link: "edit-product",
          roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.SHOP_ASSISTANT]
        },
        {
          text: "Termék hozzáadása",
          link: "add-product",
          roles: [ROLES.ADMIN, ROLES.MANAGER]
        },
        {
          text: "Rendelési összeg",
          link: "min-order-price",
          roles: [ROLES.ADMIN, ROLES.MANAGER]
        },
        {
          text: "Szállítási idő",
          link: "delivery-time",
          roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.SHOP_ASSISTANT]
        },
        {
          text: "Eseménynapló",
          link: "event-log",
          roles: [ROLES.ADMIN, ROLES.MANAGER]
        },
        {
          text: "Felhasználók",
          link: "users",
          roles: [ROLES.ADMIN]
        },
        {
          text: "Felhasználó hozzáadása",
          link: "add-user",
          roles: [ROLES.ADMIN, ROLES.MANAGER, ROLES.SHOP_ASSISTANT]
        }
      ],
      users: {
        headers: [
          { text: "Azonosító", type: "id" },
          { text: "Regisztárció", type: "date" },
          { text: "Név", type: "name" },
          { text: "E-mail", type: "email" },
          { text: "Besorolás", type: "role" },
          { text: "További részletek", type: null }
        ],
        roles: {
          ADMIN: "Admin",
          MANAGER: "Menedzser",
          SHOP_ASSISTANT: "Eladó",
          CUSTOMER: "Vásárló"
        },
        phone: "Telefonszám",
        addresses: "Mentett címek",
        chooseRole: "Válassz szerepkört",
        addUser: "Felhasználó hozzáadása"
      },
      orderMonitor: {
        title: "Mai rendelések",
        NEW: "Új rendelések",
        PREPARING: "Készülő rendelések",
        DELIVERING: "Szállítás alatti rendelések",
        DELIVERED: "Kiszállított rendelések"
      },
      coupons: {
        headers: [
          { text: "Kód", type: null },
          { text: "Engedmény(%)", type: null },
          { text: "Aktív", type: null }
        ],
        titles: {
          addNew: "Új kupon hozzáadása",
          allCoupon: "Összes kupon"
        }
      },
      products: {
        headers: [
          { text: "Név", type: "name" },
          { text: "Kategória", type: "type" },
          { text: "Csoport", type: "group" },
          { text: "Rendelhető", type: "orderable" },
          { text: "", type: null }
        ]
      },
      dailySummary: {
        headers: [
          { text: "Csoport" },
          { text: "Rendelések száma" },
          { text: "Nem fizetettek száma" },
          { text: "Kifizetetlen összeg" },
          { text: "Befolyt összeg" }
        ]
      },
      orderActivity: {
        title: "Rendelések",
        off: "Ki",
        on: "Be",
        helperText:
          "Ezzel a kapcsolóval leállíthatja vagy elindíthatja a rendeléseket. Csak szükség esetén használja!"
      },
      orderInfo: {
        minOrderTitle: "Minimális rendelési összeg",
        minOrderLabel: "ft",
        actualPrice: "Jelenlegi: {{ price }} Ft",
        deliveryTimeTitle: "Szállátási idő",
        deliveryTimeLabel: "perc",
        actualTime: "Jelenlegi: {{ time }} perc"
      },
      eventLog: {
        text:
          "<0>{{ date }}:</0> <2>{{ name }}</2> átállította a <4>#{{ orderId }}</4> azonosítójú rendelésnél a <6>$t(dashboard.eventLog.{{ eventType }}.field)</6> mezőt <8>$t(dashboard.eventLog.{{ eventType }}.status.{{ from }})</8>$t(dashboard.eventLog.{{ eventType }}.from) <10>$t(dashboard.eventLog.{{ eventType }}.status.{{ to }})</10>$t(dashboard.eventLog.{{ eventType }}.to)",
        ORDER_PAYMENT: {
          field: "fizetve",
          from: "-ről",
          to: "-re",
          status: {
            true: "Igen",
            false: "Nem"
          }
        },
        ORDER_STATE: {
          field: "státusz",
          from: "-ról",
          to: "-ra",
          status: {
            NEW: "Új",
            PREPARING: "Készítés alatt",
            DELIVERING: "Szállítás alatt",
            DELIVERED: "Kiszállítvá"
          }
        },
        noLog: "Ezen a napon nem található naplózott esemény."
      }
    },
    footer: {
      contactsTitle: "Elérhetőségek",
      contacts: [
        "betyaros@betyaros.hu",
        "+36/70/3125125",
        "Kövess minket Facebookon!"
      ],
      openingHoursTitle: "Házhozszállítás",
      openingHours: [
         "Hétfő-Vasárnap: 11:00 - 22:00"
      ],
      copyrightTexts: [
        { text: "© 2020 Betyáros", link: null },
        { text: "Minden jog fenntartva", link: null },
        { text: "Adatkezelési tájékoztató", link: privacy },
        { text: "Általános Szerződési Feltételek", link: terms }
      ]
    },
    products: {
      details: {
        name: "Termék neve",
        nameEnglish: "Termék neve angolul",
        description: "Termék leírása",
        descriptionEnglish: "Termék leírása angolul",
        price: "Termék ára (Ft)"
      },
      types: {
        FOOD: "Étel",
      },
      orderable: "Rendelhető",
      notOrderable: "Nem rendelhető",
      allergensTitle: "Allergének",
      chooseOrderable: "Válassza ki, hogy rendelhető-e",
      addNew: "Új termék hozzáadása",
      edit: "Termék szerkesztése",
      emptyProducts: "Jelenleg nincs ilyen termék",
      groups: {
        STARTER: "Előételek és Szendvicsek",
        SOUP: "Levesek",
        POULTRY: "Szárnyas ételek",
        FISH: "Halételek",
        PORK: "Sertéshúsból készült ételek",
        BEEF: "Marhahúsból készült ételek",
        WOODEN_PLATE: "Betyártálak",
        GARNISH: "Köretek",
        PICKLE: "Savanyúságok",
        DESSERT: "Desszertek",
      }
    },
    foods: {
      choosableCategories: "Választható kategóriák",
      categories: [
        { name: "Előételek", group: "appetisers" },
        { name: "Levesek", group: "soups" },
        { name: "Szárnyasok", group: "poultry" },
        { name: "Halak", group: "fish" },
        { name: "Sertéshúsból készült ételek", group: "pork" },
        { name: "Marhahúsból készült ételek", group: "beef" },
        { name: "Betyártálak", group: "betyaros-dishes" },
        { name: "Köretek", group: "garnishes" },
        { name: "Savanyúságok", group: "pickles" },
        { name: "Desszertek", group: "desserts" }
      ],
      allergens: ["Gluténmentes", "Laktózmentes", "Csípős", "Vegetáriánus"],
      appetisers: {
        title: "Előételek",
      },
      soups: {
        title: "Levesek",
      },
      poultry: {
        title: "Szárnyasok",
      },
      fish: {
        title: "Halak",
      },
      pork: {
        title: "Sertéshúsból készült ételek",
      },
      beef: {
        title: "Marhahúsból készült ételek",
      },
      "betyaros-dishes": {
        title: "Betyártálak",
      },
      garnishes: {
        title: "Köretek",
      },
      pickles: {
        title: "Savanyúságok",
      },
      desserts: {
        title: "Desszertek",
      }
    },
  }
};
