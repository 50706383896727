import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import images from "assets/images";

export const Container = styled.div`
  position: relative;
  padding: 30px 10px;
  min-width: 260px;
  background-image: url(${images.BackgroundGreen});
  background-attachment: fixed;
`;

export const Wrapper = styled(Container)`
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

export const Hamburger = styled.div`
  width: 30px;
  height: 4px;
  position: relative;
  background: ${props => props.theme.colors.light};
  transition: transform 0.5s ease;
  :before,
  :after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: -10px;
    background: ${props => props.theme.colors.light};
    transition: transform 0.3s ease;
  }
  :after {
    top: 10px;
  }
  ${props =>
    props.theme.open &&
    css`
      background: transparent;
      :before {
        top: 0;
        transform: rotate(-45deg);
      }
      :after {
        top: 0;
        transform: rotate(45deg);
      }
    `}
`;

export const Title = styled.div`
  margin: 30px 0;
  font-size: ${p => (p.size ? p.size : 50)}px;
  font-family: SpicyRice;
  color: ${props => props.theme.colors.primary};
  @media (max-width: 600px) {
    font-size: 40px;
  }
`;

export const SubTitle = styled(Title)`
  margin: 20px 0;
  font-size: 28px;
  @media (max-width: 600px) {
    font-size: 25px;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  min-width: 130px;
  min-height: 35px;
  margin: 15px 0;
  padding: 0 20px;
  font-size: 18px;
  letter-spacing: 1px;
  font-family: EncSans;
  color: ${props => props.theme.colors.light};
  background: ${props => props.theme.colors.primary};
  transition: opacity 0.3s;
  :hover {
    opacity: 0.8;
  }
  ${props =>
    props.disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`;

export const ErrorMsg = styled.div`
  font-size: 16px;
  width: 100%;
  color: ${props => props.theme.colors.error};
`;

export const Form = styled.form`
  margin-top: 30px;
  input {
    color: ${props => props.color && props.theme.colors[props.color]};
    background: ${props => props.bg && props.theme.colors[props.bg]};
  }
`;

export const Text = styled.div`
  font-size: 20px;
  color: ${props => props.theme.colors.primary};
  @media (max-width: 600px) {
    font-size: 15px;
  }
`;

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid ${props => props.theme.colors.shadow};
  flex-wrap: wrap;
  font-weight: ${props => props.bold && "bold"};
`;

export const TableHead = styled(TableRow)`
  font-weight: bold;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const TableColumn = styled.div`
  color: ${props => props.theme.colors.primary};
  font-size: 18px;
  span {
    display: none;
    font-weight: bold;
  }
  :not(:nth-child(1)) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  :nth-child(1) {
    width: 50%;
  }
  :nth-child(2) {
    width: 20%;
  }
  :nth-child(3) {
    width: 10%;
  }
  :nth-child(4) {
    width: 10%;
  }
  :nth-child(5) {
    width: 10%;
  }
`;

export const EqualTableColumn = styled(TableColumn)`
  text-align: center;
  ${({ totalColumn }) => css`
    :nth-child(-n + ${totalColumn}) {
      width: calc(100% / ${totalColumn});
      display: flex;
      justify-content: center;
    }
  `}
`;

export const SubNavigation = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 900px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

export const SubNavItem = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px 30px;
  margin: 0 10px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  border-radius: 6px;
  letter-spacing: 1px;
  transition: background-color 0.3s, color 0.3s;
  color: ${props => props.theme.colors.primary};
  ${props =>
    props.selected &&
    css`
      color: ${props.theme.colors.primary};
      background: ${props.theme.colors.transparentWhite};
    `}
  :hover {
    background: ${props =>
      !props.selected && props.theme.colors.transparentWhite};
  }
  @media (max-width: 900px) {
    margin: 0;
    border-radius: 0;
    text-align: left;
    ${props =>
      props.selected &&
      css`
        color: ${props.theme.colors.primary};
        background: ${props.theme.colors.transparentWhite};
      `}
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  & > div:nth-child(1) {
    text-align: left;
    margin-right: 25px;
  }
  ${props =>
    props.vertical &&
    css`
      flex-direction: column;
      align-items: flex-start;
      & > div:nth-child(2) {
        text-align: left;
        margin: 15px 0 0;
      }
    `}
`;

export const Bold = styled.div`
  font-weight: bold;
`;

export const SlideContainer = styled.div`
  transition: max-height 1s ease-out;
  max-height: 100vh;
  overflow: auto;
  margin: 25px auto;
  padding: 0 30px;
  ${props =>
    !props.open &&
    css`
      max-height: 0;
      margin: 0 auto;
      pointer-events: none;
      transition: max-height 1s ease-out, margin 0.5s ease 0.8s;
    `};
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.shadow};
    border-radius: 100px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.transparentSecondary};
    border-radius: 100px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.secondary};
  }
`;

export const HideContainer = styled.div`
  overflow: hidden;
  transition: ${({ duration = "500ms" }) =>
    `max-height ${duration} ease-in-out`};
  max-height: ${({ show, maxHeight }) => (show ? maxHeight || "500px" : "0")};
`;
