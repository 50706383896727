import React from "react";
import styled from "styled-components";
import { useEffect } from "react";
import { getShippingTime } from "utils/dataHandler";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import images from "assets/images";

const Wrapper = styled.div`
  max-width: 480px;
  padding: 10px;
  margin: -30px auto -15px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: ${p => p.theme.colors.light};
  background-image: url(${images.BackgroundRed});
  background-attachment: fixed;
  letter-spacing: 1px;
  border-radius: 0 0 8px 8px;
`;

const DeliveryTimeNotification = () => {
  const { t: translate } = useTranslation();
  const [time, setTime] = useState(0);

  useEffect(() => {
    getShippingTime().then(time => setTime(time));
  });

  return <Wrapper>{translate("cart.deliveryTimeText", { time })}</Wrapper>;
};

export default DeliveryTimeNotification;
