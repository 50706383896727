import React, { createContext } from "react";
import { useTranslation } from "react-i18next";
import NotificationManager from "components/utils/notification";

const manager = NotificationManager;

export const NotificationContext = createContext();

const NotificationContextProvider = ({ children }) => {
  const { t: translate } = useTranslation();

  const notificationModels = {
    serverError: () =>
      manager.create({
        type: "error",
        title: translate("error.server.title"),
        text: translate("error.server.text")
      }),

    successRegistration: () =>
      manager.create({
        type: "success",
        timeout: 10000,
        title: translate("success.registration.title"),
        text: translate("success.registration.text")
      }),
    successLogin: () =>
      manager.create({
        type: "success",
        text: translate("success.login")
      }),
    noAccess: () =>
      manager.create({
        type: "warning",
        title: translate("error.noAccess.title"),
        text: translate("error.noAccess.text")
      }),
    successModification: what =>
      manager.create({
        type: "success",
        title: translate("success.modification.title"),
        text: translate("success.modification.text", {
          what: translate(`success.modification.${what}`)
        })
      }),
    successNewAddress: () =>
      manager.create({
        type: "success",
        text: translate("success.added.address")
      }),
    successDeleteAddress: () =>
      manager.create({
        type: "success",
        text: translate("success.deleted.address")
      }),
    successAddToCart: () =>
      manager.create({
        type: "success",
        timeout: 2000,
        title: translate("success.added.cart.title"),
        text: translate("success.added.cart.text")
      }),
    successRemoveFromCart: () =>
      manager.create({
        type: "success",
        timeout: 2000,
        title: translate("success.deleted.cart.title"),
        text: translate("success.deleted.cart.text")
      }),
    successRedeemCoupon: () =>
      manager.create({
        type: "success",
        title: translate("success.redeemCoupon.title"),
        text: translate("success.redeemCoupon.text")
      }),
    successSubmitOrder: () =>
      manager.create({
        type: "success",
        title: translate("success.order.title"),
        text: translate("success.order.text")
      }),
    newOrderReceived: () =>
      manager.create({
        type: "orange",
        text: translate("success.newOrderReceived")
      }),
    successModify: () =>
      manager.create({
        type: "success",
        timeout: 2000,
        text: translate("success.modify")
      }),
    successAdded: () =>
      manager.create({
        type: "success",
        timeout: 2000,
        text: translate("success.added.added")
      }),
    successDelete: () =>
      manager.create({
        type: "success",
        timeout: 2000,
        text: translate("success.deleted.deleted")
      }),
    successAddUser: () =>
      manager.create({
        type: "success",
        title: translate("success.added.user.title"),
        text: translate("success.added.user.text")
      }),
    cardPaymentFailed: () =>
      manager.create({
        type: "warning",
        timeout: 8000,
        title: translate("error.payment.title"),
        text: translate("error.payment.text")
      }),
    orderingIsNotActive: () =>
      manager.create({
        type: "warning",
        timeout: 10000,
        title: translate("error.order.title"),
        text: translate("error.order.text")
      })
  };

  return (
    <NotificationContext.Provider
      value={{
        notificationModels
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
